// import React from 'react'

// const About = () => (
//   <div>
//     <h1>About Page</h1>
//     <p>这是About页面</p>
//   </div>
// )

// export default About

import React, { useEffect } from 'react'
// 导入React Redux提供的Hooks
import { useDispatch, useSelector } from 'react-redux'
import {  decrement, fetchUsers, incrementByAmount } from '../../store/modules/users'

const About = () =>{
	// 结构出 list
	const { list, count } = useSelector(state => state.users)
	const dispatch = useDispatch()
	// 使用useEffect触发异步请求执行
	useEffect(()=>{
		dispatch(fetchUsers())
	},[dispatch])
	return (
		<div>
			<h2>当前users中count的值为：{count}</h2>
			<div>
				<button onClick={() => dispatch(decrement())}>count+1</button>
				<button onClick={() => dispatch(decrement())}>count-1</button>
				<button onClick={() => dispatch(incrementByAmount(-10))}>count-10</button>
				<button onClick={() => dispatch(incrementByAmount(10))}>count+10</button>
				<button onClick={() => dispatch(fetchUsers())}>重新请求</button>
			</div>
			<h2>当前usersz中list的值为：{list}</h2>
			<ul>
				{list.map(item => <li key={item}>{item}</li>)}
			</ul>
		</div>
	)
}

export default About
