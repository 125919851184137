// 1. 导入Reduxjs Toolkit中的createSlice方法
import { createSlice } from '@reduxjs/toolkit';
// 2. 创建一个名为users的slice
const usersSlice = createSlice({
	 name: 'users',
	 initialState: {
		 list: [],
		 count: 0,
	 },
	 reducers: {
		// 定义一个名为increment的action 因为是同步方法 支持直接修改state
		increment: (state) => {
			// 更新state.count为state.count + 1
			state.count++;
		},
		decrement: (state) => {
			// 更新state.count为state.count - 1
			state.count--;
		},
		// 定义一个名为incrementByAmount的action
		incrementByAmount: (state, action) => {
			// 更新state.count为state.count + action.payload
			state.count += action.payload;
		},
	   // 定义一个名为setUsers的action
	   setUsers: (state, action) => {
	     // 更新state.list为action.payload
	     state.list = action.payload;
	   },
	 },
})
// 3. 解构出来的actionCreater函数
const { increment, decrement, incrementByAmount, setUsers } = usersSlice.actions;

// 模拟异步返回数据
const _callQueryResult = () => {
  const start = new Date().getTime();
  function _generateRandomNumber () {
    return Math.floor(Math.random() * 1000); //生成0到100之间的随机数
  }
  return new Promise(resolve => {
    console.log('%c\n--------响应开始--------', 'color:green;');
    setTimeout(() => {
      const end = new Date().getTime();
      let arr = []
	   // 生成五个随机数
      for (let index = 0; index < 5; index++) {
        arr.push(_generateRandomNumber())
      }
      console.log(`%c--------响应结束--------\n耗时${end - start}ms\n${arr}`, 'color:red;');
      resolve(arr)
    }, 1000)
  })
}
// 异步请求数据
const fetchUsers = () => async (dispatch) => {
	 // 发送异步请求
	 const data = await _callQueryResult();
	 // 派发action，更新state.list
	 dispatch(setUsers(data));
};

// 4. 以按需导出的方式导出actionCreater
export {
	increment,
	decrement,
	incrementByAmount,
	fetchUsers
}
// 5. 以默认导出的方式导出reducer
export default usersSlice.reducer;
