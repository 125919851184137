import './App.css';
import Router from "./router.js"
import React from 'react';
function App() {
  return (
    <div className="App">
      <Router/>
    </div>
  );
}
 
export default App;