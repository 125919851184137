import React, { Component } from 'react'
import { Route, Switch, HashRouter } from 'react-router-dom'
import About from './pages/About'
import Home from './pages/Home'
// import Info from './page/Info/Info'

//class定义
export default class Router extends Component {
    render() {
        return (
            // 建议使用 HashRouter
            <HashRouter>
                <Switch>
                    <Route path="/" exact component={Home}></Route>
                    <Route path="/home" component={Home} />
                    <Route path="/About" component={About} />
                    {/* <Redirect from="路径" to="需要跳转到的路径" /> */}
                    {/* <Route component={ } /> */}
                </Switch>
            </HashRouter>
        )
    }
}