import React, { useRef, useState, useEffect } from 'react';
import TweenOne from 'rc-tween-one';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { Row, Col } from 'antd';
import QueueAnim from 'rc-queue-anim';
import { getChildrenToRender } from './utils';

// class VideoPopup extends React.PureComponent {
//   render() {
//     // const { videoSrc, onClose } = this.props;
//     const  videoSrc= 'https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-official/video/yunzhenka.mp4';
//     const handleCloseClick = () => {
//       console.log('关闭');

//       this.setState({ isVisible: false });
//     };

//     return (
//       <div className="popup">
//         <div className="popup-content">
//           <span className="close-btn" onClick={handleCloseClick}>
//             &times;25362
//           </span>
//           <video controls >
//             <source src={videoSrc} type="video/mp4" />
//             Your browser does not support the video tag.
//           </video>
//         </div>
//       </div>
//     );
//   }
// }


const VideoPopup = ({ videoSrc, onClose }) => {
  // 使用 useRef 来访问 video 元素
  const videoRef = useRef(null);

  // 处理关闭逻辑，停止视频播放并调用 onClose
  // const onClose = () => {
  //   console.log('点击关闭');

  //   if (videoRef.current) {
  //     videoRef.current.pause(); // 停止视频播放
  //     videoRef.current.currentTime = 0; // 重置视频时间
  //   }
  // };
  // const onClose = ()=>{
  //   console.log('关闭');

  // }
  // function onClose(params) {
  //   console.log('关闭','isVisible',);
  //   handleCloseClick()

  // }

  return (
    <div className="popup">
      <div className="popup-content">
        <span className="close-btn" onClick={onClose}>
          &times;
        </span>
        <video ref={videoRef} controls>
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

// class contentVideo extends React.PureComponent {
//   constructor(props) {
//     super(props);
//     this.state = {
//       isVisible: true
//     };
//   }

//   handlePlayClick = () => {
//     const { onClick } = this.props;
//     if (onClick) {
//       console.log('点击');

//       onClick();
//     }
//     this.setState({ isVisible: true });
//   };

//   // onClose=()
//   handleCloseClick = () => {
//     console.log('关闭');

//     this.setState({ isVisible: false });
//   };

//   getDelay = (e, b) => (e % b) * 100 + Math.floor(e / b) * 100 + b * 100;

//   getBlockChildren = (item, i) => {
//     const children = item.children;
//     const delay = this.props.isMobile ? i * 50 : this.getDelay(i, 24 / item.md);
//     const liAnim = {
//       y: 30,
//       opacity: 0,
//       type: 'from',
//       ease: 'easeOutQuad',
//       delay,
//     };
//     return (
//       <TweenOne component={Col} animation={liAnim} key={i.toString()} {...item}>
//         <div {...children}>
//           <div className="image-wrapper" {...children.img}>
//             <img src={children.img.children} alt="img" />
//           </div>
//           <h2 {...children.title}>{children.title.children}</h2>
//           <div {...children.content}>{children.content.children}</div>
//         </div>
//       </TweenOne>

//     );
//   };

//   render() {
//     const { isVisible } = this.state;
//     const { ...props } = this.props;
//     console.log(props,'props');
//     const  videoSrc= 'https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-official/video/yunzhenka.mp4';

//     const { dataSource } = props;
//     delete props.dataSource;
//     delete props.isMobile;
//     const children = dataSource.block.children.map(this.getBlockChildren);
//     console.log(children,'children1'); //[0].props.children.props.videoSrc

//     return (
//       <div {...props} {...dataSource.wrapper}>
//         <div {...dataSource.page}>
//           <div {...dataSource.titleWrapper}>
//             {dataSource.titleWrapper.children.map(getChildrenToRender)}
//           </div>
//           <OverPack {...dataSource.OverPack}>
//             <QueueAnim type="bottom" key="img">
//               <Row {...dataSource.block}   key="img">
//                 {children}
//               </Row>
//             </QueueAnim>
//           </OverPack>
//         </div>
//         {isVisible && (
//           <VideoPopup videoSrc={videoSrc} />
//         )}
//       </div>
//     );
//   }
// }

const contentVideo = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [videoSrc, setIsVideoSrc] = useState('https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-official/video/xuanchuan.m4v');
  const handleCloseClick = () => {
    console.log(isVisible);
    console.log('关闭');
    setIsVisible(false);
    console.log(isVisible);

  };
  //   const handleCloseClick = useCallback(() => {
  //   console.log('关闭');
  //   setIsVisible(false);
  //   console.log(isVisible);

  // }, []);


  const videoList = [
    'https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-official/video/xuanchuan.m4v',
    'https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-official/video/antyunyi.mp4',
    'https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-official/video/originator.mp4',
    'https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-official/video/jiankanbao.mp4',
    'https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-official/video/yunzhenka.mp4',
    'https://drant-ehos-public.obs.cn-south-1.myhuaweicloud.com/open/drant-official/video/zhulishi.mp4',
  ]
  const handlePlayClick = (index) => {
    console.log(index, 'index');
    setIsVideoSrc(videoList[index])
    setIsVisible(true);
  };

  useEffect(() => {
    console.log('isVisible changed:', isVisible);
  }, [isVisible]);

  const getDelay = (e, b) => (e % b) * 100 + Math.floor(e / b) * 100 + b * 100;

  const getBlockChildren = (item, i) => {
    const children = item.children;
    const delay = props.isMobile ? i * 50 : getDelay(i, 24 / item.md);
    const liAnim = {
      y: 30,
      opacity: 0,
      type: 'from',
      ease: 'easeOutQuad',
      delay,
    };
    return (
      <TweenOne component={Col} animation={liAnim} key={i.toString()} {...item}>
        <div {...children}>
          <div className="image-wrapper" {...children.img}>
            <img src={children.img.children} alt="img" />
          </div>
          <h2 {...children.title}>{children.title.children}</h2>
          <div {...children.content}>{children.content.children}</div>
        </div>
      </TweenOne>
    );
  };

  const { dataSource, isMobile, ...restProps } = props;

  const children = dataSource.block.children.map((item, index) => getBlockChildren(item, index));
  console.log(children, 'children2');


  return (
    <div {...restProps} {...dataSource.wrapper}>
      <div {...dataSource.page}>
        <div {...dataSource.titleWrapper}>
          {dataSource.titleWrapper.children.map(getChildrenToRender)}
        </div>
        <OverPack {...dataSource.OverPack}>
          <QueueAnim type="bottom" key="img">
            <Row {...dataSource.block} key="img">
              {/* <div className=''>{children}</div> */}
              {children.map((item, index) => (
                <div className='contentVideo-block-wrapper' key={index} onClick={() => handlePlayClick(index)}>{item}</div>
              ))}
            </Row>
          </QueueAnim>
        </OverPack>
      </div>
      {isVisible && (
        <VideoPopup videoSrc={videoSrc} onClose={handleCloseClick} />
      )}
    </div>
  );
};
export default contentVideo;
